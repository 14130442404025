export function noLocalStorage() {
    try {
        // Try using localStorage
        const test = '__ls__';

        localStorage.setItem(test, test);
        localStorage.removeItem(test);

        // If no error, localStorage is available
        return false;
    } catch (e) {
        // If an error is caught, localStorage is not available
        return true;
    }
}
